import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { CSSObject } from 'styled-components';

const style: CSSObject = {
  maxWidth: '580px',
  margin: 'auto',
  position: 'relative',
  display: 'block'
};

export const LenderNetworkHeaderImage = () => (
  <StaticImage
    src="../../../../images/own-up-on-devices.png"
    alt="Two people shaking hands at the end of a meeting"
    quality={100}
    placeholder="blurred"
    loading="eager"
    style={style}
  />
);

export const WhyOwnUpImage = () => (
  <StaticImage
    src="../../../../images/liz-and-matt.png"
    alt="Own Up Employee K, working at a laptop"
    quality={100}
    placeholder="blurred"
    style={style}
  />
);

export const AboutImage = () => (
  <StaticImage
    src="../../../../images/mike-and-patrick-in-park-square.png"
    alt="'Two happy Own Up customers smile at the camera"
    quality={100}
    placeholder="blurred"
    style={style}
  />
);
